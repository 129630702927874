<template>
<!-- 打印小品订单信心组件 -->
  <div class="receiptConfInfo">
       <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
          <span
            class="font_12 text_right line_height_24"
            style="flex:3;"
          >{{orderInfo.ID}}</span>
        </div>
        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
          <span
            class="font_12 text_right line_height_24"
            style="flex:3;"
          >{{orderInfo.BillDate}}</span>
        </div>
        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
          <span
            class="flex_box font_12 text_right line_height_24"
          >{{receiptConf.NameEncrypt?formatName(orderInfo.Name) : orderInfo.Name}}</span>
        </div>
        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
          <span
            class="flex_box font_12 text_right line_height_24"
          >{{receiptConf.MobileEncrypt?formatPhone(orderInfo.PhoneNumber):orderInfo.PhoneNumber}}</span>
        </div>
        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
          <span
            class="flex_box font_12 text_right line_height_24"
          >{{orderInfo.EmployeeName}}</span>
        </div>
        <div v-if="receiptConf.EntityAddress" class="dis_flex">
          <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
          <span class="flex_box font_12 text_right line_height_24">{{orderInfo.AddressDetail}}</span>
        </div>
    
  </div>
</template>

<script>
import printReceipt from "@/components/js/print";
export default {
 name: 'receiptConfInfo',
  props:{
    orderInfo:{
      type:Object,
      require:true,
    },
    receiptConf:{
      type:Object,
      require:true,
    }
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {

    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {

    // 姓名隐藏
    formatName(name) {
      return printReceipt.hiddenName(name);
    },
    // 手机号隐藏
    formatPhone(phone) {
      return printReceipt.hiddenPhone(phone);
    },

  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  实例被挂载后调用  */
  mounted() {},
}
</script>

<style lang="scss">

.receiptConfInfo{

 }
</style>
